<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <page-header-layout v-show="$route.matched.length==2">
      <div class="main-page-content"
           v-loading="loadingStatus"
           :element-loading-text="loadingText">
        <el-row class="table-header" type="flex" justify="space-between">
          <el-col>
            <div style="display: flex;justify-content: space-between;padding-bottom: 12px">
          <el-button type="primary" size="medium"
                     v-if="userPermissions.indexOf('exam_guide_create') != -1 "
                     @click="addButton()">添加
          </el-button>

              <el-switch
                  v-model="isSort"
                  inactive-text="列表排序">
              </el-switch>
        </div>
          </el-col>
        </el-row>

        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="标题">
              <el-input placeholder="请输入标题" clearable v-model="searchForm.title"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="examGuideList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
<!--          <el-table-column-->
<!--              slot="first-column"-->
<!--              width="80"-->
<!--              align="center"-->
<!--              label="Drag">-->
<!--            <template slot-scope="scope">-->
<!--              <el-tooltip effect="dark" content="拖动排序" placement="top-start">-->
<!--                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>-->
<!--              </el-tooltip>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
              v-if="buttonType=='icon'"
              label="操作"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <span v-if="!isSort">
                     <el-tooltip effect="dark" content="文章" placement="top-start"
                                 v-if="userPermissions.indexOf('exam_guide_add') != -1">
                  <el-button size="mini" type="primary" icon="el-icon-tickets"
                             @click="addArticleButton(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('exam_guide_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="删除" placement="top-start" v-if="userPermissions.indexOf('exam_guide_delete') != -1">
                  <el-button  type="danger" size="mini" @click="deleteButton(scope.row.id)" class="el-icon-delete"></el-button>
                </el-tooltip>
              </span>
              <span v-if="isSort">
                <el-tooltip effect="dark" content="置顶" placement="top-start">
                    <el-button size="mini" icon="el-icon-upload2" @click="onStickClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="置底" placement="top-start">
                    <el-button size="mini" icon="el-icon-download" @click="onUndersideClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="向上排序" placement="top-start">
                    <el-button size="mini" icon="el-icon-top" @click="onUpwardClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="向下排序" placement="top-start">
                    <el-button size="mini" icon="el-icon-bottom" @click="onDownwardClick(scope.row.id)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='text'"
              label="操作"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <div>
                <el-button size="mini" type="primary" v-if="userPermissions.indexOf('exam_guide_add') != -1"
                           @click="addArticleButton(scope.row)">文章
                </el-button>

                <el-button size="mini"
                           v-if="userPermissions.indexOf('exam_guide_edit') != -1"
                           @click="editButton(scope.row.id)">编辑
                </el-button>

                <el-button size="mini" type="danger"
                           v-if="userPermissions.indexOf('exam_guide_delete') != -1"
                           @click="deleteButton(scope.row.id)">删除
                </el-button>
<!--                <el-popover-->
<!--                    v-if="userPermissions.indexOf('exam_guide_delete') != -1"-->
<!--                    placement="top"-->
<!--                    width="150"-->
<!--                    v-model="scope.row.visible">-->
<!--                  <p>确定要删除记录吗？</p>-->
<!--                  <div style="text-align: right; margin: 0;">-->
<!--                    <el-button type="text" size="mini" @click="scope.row.visible=false">取消-->
<!--                    </el-button>-->
<!--                    <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定-->
<!--                    </el-button>-->
<!--                  </div>-->
<!--                  <el-button slot="reference" type="danger" size="mini" @click="deleteButton(scope.row.id)">删除</el-button>-->
<!--                </el-popover>-->
              </div>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </page-header-layout>
    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-col :span="18" class="content-left">
          <el-form ref="specialTopicForm" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="标题" prop="title">
              <el-input v-model="formData.title" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="序号" prop="order">
              <el-input v-model="formData.order" maxlength="5" onkeyup="value=value.replace(/\D/g,'')"
                        @input="isChangeOrder" :disabled="orderDisabled" :placeholder="orderPlaceholder"></el-input>
              <div style="color: #999999;font-size: 12px">(数值越低排序越靠前)</div>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="formData.status">
                <el-radio label="0" border>启用</el-radio>
                <el-radio label="-1" border>禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>
      </template>
    </ApeDrawer>

    <el-dialog :destroy-on-close="true" :visible.sync="isArticleDialog" :close-on-click-modal="false" width="80%" :title="curExamGuideTitle + '文章列表'"
               :append-to-body="true" fullscreen style="margin: 10px" @opened="onOpenedArticle">
      <exam-guide-article-list ref="examGuideArticleRef"></exam-guide-article-list>
    </el-dialog>
  </div>
</template>

<script>

const DEL = "del";
const NO_DEL = "no_del";

import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import ApeDrawer from '@/components/ApeDrawer';
import ExamGuideArticleList from "@/pages/exam_guide/component/ExamGuideArticleList";
import {mapGetters} from 'vuex';
import elTableInfiniteScroll from 'el-table-infinite-scroll';

export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    ExamGuideArticleList
  },

  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },

  data() {
    return {
      orderDisabled: false,
      isSort: false,
      loadingText: "加载中",
      loadingStatus: false,
      examGuideList: [],
      columns: [
        {
          title: '序号',
          value: 'order',
          width: '80'
        },
        {
          title: "标题",
          value: "title",
          width: "500"
        },
        {
          title: "文章数",
          value: "resource_count"
        },
        {
          title: "创建时间",
          value: "created_at"
        }

      ],
      // 表格列表数据
      questionBankList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },
      //抽屉表单
      formData: {
        title: '',
        status: '0',
        order: ''
      },
      // 表单验证
      rules: {
        title: [
          {required: true, message: '输入标题', trigger: 'blur'},
        ],
        order: [{required: true, message: '请输入序号', trigger: 'blur'}],
      },

      searchForm: {},

      //专题ID
      // sTId: "",

      //弹框配置
      dialogData: {
        visible: false,
        title: "选择文章",
        width: '50%'
      },
      //搜索条件
      searchCondition: {
        excerpt: "",
        title: ""
      },
      //文章列表
      articleList: [],

      //文章表格
      articleColumns: [
        {
          title: "标题",
          value: 'title',
        },
        {
          title: "关键字",
          value: 'keyword'
        },
        {
          title: "封面",
          type: "image",
          value: 'cover_img',
          url: "cover_pic_url"
        },
        {
          title: "缩略图",
          type: "image",
          value: "thumb_img",
          url: "thumb_pic_url"
        },
        {
          title: "摘要",
          value: "excerpt"
        }
      ],

      totalCount: null,
      //分页信息
      pagingInfo: {
        page_size: 10,
        current_page: 1
      },
      selectArticleList: [],
      stcId: "",
      selectArticleId: [],
      specialTopicName: "",
      searchParams: {},
      isArticleDialog: false,
      curExamGuideTitle: "", //当前点击标题
      curExamGuideId: "", //当前点击ID
    }
  },

  mounted() {
    this.getExamGuideList(DEL);
  },

  methods: {
    // 切换页码操作
    async switchPaging() {
      this.getExamGuideList(NO_DEL)
    },

    // 响应添加按钮
    async addButton() {
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '添加考试指南';
      this.orderDisabled = true
      this.$set(this.formData, 'order', 0)
      this.$nextTick(() => {
        this.drawerData.loading = false;
      });
    },

    // 响应编辑按钮
    async editButton(id) {
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '修改考试指南';
      let {info} = await this.$api.getStudyGuideInfo({id});
      this.$nextTick(() => {
        this.drawerData.loading = false;
        this.formData = info;
      });
    },

    //响应
    addArticleButton(row) {
      this.curExamGuideTitle = row.title
      this.curExamGuideId = row.id
      this.isArticleDialog = true
      // this.$router.push(this.$route.path + '/' + id + '/select');
    },

    onOpenedArticle() {
      this.$refs.examGuideArticleRef.setExamGuideId(this.curExamGuideId)
    },

    //获取专题名称
    async getSpecialTopicName() {
      let id = this.$route.params.s_id;
      let {info} = await this.$api.getSpecialTopicInfo({id});
      this.specialTopicName = info.title;
    },

    // 处理抽屉关闭
    drawerClose() {
      this.resetFormData();
      this.drawerData.visible = false;
      this.drawerData.loading = true
    },

    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['specialTopicForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },

    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……';
      this.drawerData.loading = true;
      // 调用接口
      let params = {}
      if (this.formData.id) {
        params.id = this.formData.id
      }
      params.title = this.formData.title
      params.status = this.formData.status
      if (this.formData.order !== "" && this.formData.order !== undefined) {
        params.order = parseInt(this.formData.order)
      }
      let id = await this.$api.storeStudyGuide(params);
      this.$nextTick(() => {
        this.drawerData.visible = false
      });
      this.$nextTick(() => {
        if (id) {
          this.getExamGuideList()
        }
      });
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      });
      this.resetFormData()
    },

    // 相应删除按钮
    async deleteButton(id) {
      console.log(id)
      this.$confirm('是否删除该考试指南?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let info = await this.$api.deleteStudyGuideInfo({id});
        if (info === 'ok') {
          this.$nextTick(() => {
            this.getExamGuideList(DEL);
            this.$message.success("删除成功");
          })
        } else {
          this.$message.error("删除失败");
        }
      })

      //
      // let info = await this.$api.deleteStudyGuideInfo({id});
      // if (info == 'ok') {
      //   this.$nextTick(() => {
      //     this.getExamGuideList(DEL);
      //     this.$message.success("删除成功");
      //   })
      // } else {
      //   this.$message.error(info)
      // }
    },

    // 重置表单数据
    resetFormData() {
      // 初始化form表单
      this.formData = {
        status: '0',
        title: "",
        order: ''
      };
      this.orderDisabled = false
      this.$nextTick(() => {
        this.$refs['specialTopicForm'].resetFields();
      })
    },

    async getExamGuideList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);

      for (let param in this.searchForm) {
        if (this.searchForm[param] == '') {
          continue;
        }
        inputData[param] = this.searchForm[param]
      }
      if (type === DEL) {
        inputData['current_page'] = 1
      }

      let {list, pages} = await this.$api.getStudyGuideList(inputData);
      this.examGuideList = [];
      this.$nextTick(() => {
        this.examGuideList = list
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit(orderIds) {
      let data = {
        ids: orderIds
      };
      let info = await this.$api.orderStudyGuide(data);
      if (info == 'ok') {
        this.$nextTick(() => {
          this.getSpecialTopicList()
        })
      }
      this.$message.success('保存成功!')
    },

    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: () => {
          let Ids = [];
          let tmp = el.querySelectorAll('.drag-handle');
          for (let i = 0, len = tmp.length; i < len; i++) {
            Ids.push(tmp[i].dataset.id)
          }
          this.dragSortSubmit(Ids)
        },
      })
    },

    //弹框确定
    // async handleConfirm() {
    //     this.dialogData.visible = false;
    //
    //     if (!this.stcId) {
    //         this.$message("分类Id为空");
    //         return;
    //     }
    //
    //     if (this.selectArticleId.length <= 0) {
    //         this.$message("请选择要添加的文章");
    //         return;
    //     }
    //
    //     let data = {
    //         stc_id: this.stcId,
    //         ids: this.selectArticleId
    //     };
    //
    //     let info = await this.$api.selectExamGuideArticle(data);
    //     if (info) {
    //         this.$message.success("添加成功");
    //     } else {
    //         this.$message.error("添加失败");
    //         return;
    //     }
    //
    //     // this.iniQuestionTypeQuestionList();
    //     this.selectArticleList = [];
    //     this.selectArticleId = [];
    //
    //     this.getExamGuideList(NO_DEL);
    // },

    //弹框取消
    // dialogClose() {
    //     this.dialogData.visible = false;
    // },

    //加载
    // loadMoreQuestionData() {
    //     if (this.loadingStatus) {
    //         return;
    //     }
    //     this.$message.success('加载下一页');
    //     this.pagingInfo.current_page++;
    //     this.getArticleListPool(NO_DEL, this.searchParams);
    //
    // },

    // async getArticleListPool(type, params = {}) {
    //     this.loadingStatus = true;
    //     let inputData = this.pagingInfo;
    //
    //     for (let param in params) {
    //         inputData[param] = params[param];
    //     }
    //
    //     if (this.stcId) {
    //         inputData.stc_id = this.stcId;
    //     }
    //     let {list, total_count} = await this.$api.getExamGuidePool(inputData);
    //
    //     if (this.pagingInfo.current_page > 1 && list.length <= 0) {
    //         this.loadingStatus = false;
    //         this.$message('已经到底了');
    //         return;
    //     }
    //
    //     if (list.length < 0) {
    //         this.$message("文章为空,请先添加文章");
    //         this.loadingStatus = false;
    //         return;
    //     }
    //
    //     this.$nextTick(() => {
    //         if (type === DEL) {
    //             this.articleList = list;
    //         } else {
    //             for (let article of list) {
    //                 this.articleList.push(article);
    //             }
    //         }
    //
    //         this.totalCount = total_count;
    //         this.loadingStatus = false
    //     });
    // },

    //搜索
    // onSearchClick() {
    //     this.pagingInfo.current_page = 1;
    //     this.searchParams = {};
    //     if (this.searchCondition && this.searchCondition.hasOwnProperty('title') && this.searchCondition["title"] !== "") {
    //         this.searchParams["title"] = this.searchCondition["title"];
    //     }
    //
    //     if (this.searchCondition && this.searchCondition.hasOwnProperty('excerpt') && this.searchCondition["excerpt"] !== "") {
    //         this.searchParams["excerpt"] = this.searchCondition["excerpt"];
    //     }
    //
    //     this.getArticleListPool("del", this.searchParams);
    //     this.getArticleListPool();
    // },

    //重置
    // onResetSearchClick() {
    //     this.searchCondition = {
    //         excerpt: "",
    //         title: ""
    //     }
    // },

    // getSelectList(list) {
    //     if (list.length > 0) {
    //         this.selectArticleList = [];
    //         for (let article of list) {
    //             let id = article.id;
    //             this.selectArticleId.push(id);
    //         }
    //     }
    // },

    onSearchClick() {
      this.getExamGuideList(DEL)
    },

    onResetSearch() {
      this.searchForm = {};
      this.getExamGuideList(DEL)
    },

    isChangeOrder(e) {
      let val = parseInt(e)
      if(!val) {
        return
      }
      this.$set(this.formData, "order", val)
      let newVal = ""
      if (val <= 0) {
        newVal = 0
        this.$set(this.formData, "order", newVal)
      } else if (val >= this.pagingData.total) {
        newVal = (this.pagingData.total - 1)
        this.$set(this.formData, "order", newVal)
      }
    },
    //置顶
    async onStickClick(id) {
      let data = {
        id : id,
        type: 'top'
      }
      await this.$api.orderStudyGuide(data)
      await this.getExamGuideList(DEL)
    },
    //置底
    async onUndersideClick(id) {
      let data = {
        id : id,
        type: 'bottom'
      }
      await this.$api.orderStudyGuide(data)
      await this.getExamGuideList(NO_DEL)
    },
    //向上排序
    async onUpwardClick(id) {
      let data = {
        id : id,
        type: 'up'
      }
      await this.$api.orderStudyGuide(data)
      await this.getExamGuideList(NO_DEL)
    },
    //向下排序
    async onDownwardClick(id) {
      let data = {
        id : id,
        type: 'down'
      }
      await this.$api.orderStudyGuide(data)
      await this.getExamGuideList(NO_DEL)
    },


  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    orderPlaceholder(){
      let placeholder = ""
      if ((this.pagingData.total-1) < 0) {
        return placeholder
      }
      placeholder = '请输入0-' + (this.pagingData.total-1) + '之间的数字'
      return placeholder
    }
  },

  watch: {
    "$route.matched": function (n, o) {
      console.log(n);
      if (n.length === 2) {
        console.log('2');
        this.getExamGuideList(NO_DEL);
      }
    },
    pagingData: {
      handler(data) {
        if(data.total <= 0) {
          this.orderDisabled = true
          this.$set(this.formData, 'order', 0)
        } else {
          this.orderDisabled = false
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
/deep/ .el-switch__label
  color #0099ff
</style>