<template>
  <div>
    <el-form :inline="true" :model="searchCondition" class="demo-form-inline" ref="formRef">
      <el-form-item label="标题">
        <el-input placeholder="请输入文章标题" clearable v-model="searchCondition.title"/>
      </el-form-item>
      <el-form-item label="摘要">
        <el-input placeholder="请输入文章摘要" clearable v-model="searchCondition.excerpt"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="onResetSearchClick">重置</el-button>
      </el-form-item>
    </el-form>
    <div  @click="onOpenSelectedArticleDialogClick">已选择<span style="color: #0099ff">{{ selectArticleId.length }}</span>项</div>
    <template  v-for="tag in selectArticleId">
      <el-popover
          :key="tag.id"
          placement="top-start"
          title="文章名称"
          width="200"
          trigger="hover"
          :content="tag.title">
        <el-tag
            closable
            slot="reference"
            :disable-transitions="false"
            @close="handleTagClose(tag)"
            style="margin-top: 6px"
        >
          {{tag.title | articleSimpleTitle}}
        </el-tag>
      </el-popover>

    </template>

    <div :style="{height: contentHeight + 'px'}" style="overflow: auto">
      <ape-table ref="apeTable" :data="articleList" :columns="columns" :loading="loadingStatus"
                 :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row
                 @select="handleSelectionChange" @selectAllOnce="onHandleSelectionAllChange">
        <el-table-column slot="first-column"
                         type="selection"
                         width="55">
        </el-table-column>
        <el-table-column
            label="封面"
            width="80"
        >
          <template slot-scope="scope">
            <img style="width: 40px;height: 26px;" :src="scope.row.cover_pic_url" />
          </template>
        </el-table-column>
        <el-table-column
            label="缩略图"
            width="80"
        >
          <template slot-scope="scope">
            <img style="width: 40px;height: 26px;" :src="scope.row.thumb_pic_url" />
          </template>
        </el-table-column>
      </ape-table>
    </div>

    <div style="float:right; padding-bottom: 24px">
      <el-button @click="dialogClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </div>
  </div>
</template>

<script>
const NO_DEL = 'no_del';
const DEL = 'del';
import elTableInfiniteScroll from 'el-table-infinite-scroll';
import ApeTable from '@/components/ApeTable';

export default {
  name: "ArticlePickDialog",
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  props: {},
  components: {ApeTable},

  data() {
    return {
      contentHeight: 0,
      //搜索条件
      searchCondition: {
        excerpt: "",
        title: ""
      },
      //文章列表
      articleList: [],
      columns: [{
        title: "标题",
        value: 'title',
        width: '400'
      },
        {
          title: "摘要",
          value: 'excerpt'
        },
        // {
        //   title: "封面",
        //   type: "image",
        //   value: 'cover_img',
        //   url: "cover_pic_url"
        // },
        // {
        //   title: "缩略图",
        //   type: "image",
        //   value: "thumb_img",
        //   url: "thumb_pic_url"
        // },
      ],
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 8
        // offset: 0, // 分页的offset,序号列使用
      },
      loadingStatus: false,

      selectArticleList: [],
      selectArticleId: [],

      articleColumns: [
        {
          title: "标题",
          value: 'title'
        },
        {
          title: "摘要",
          value: 'excerpt'
        },
        {
          title: "封面",
          type: "image",
          value: 'cover_img',
          url: "cover_pic_url"
        },
        {
          title: "缩略图",
          type: "image",
          value: "thumb_img",
          url: "thumb_pic_url"
        },
      ],

      totalCount: null,
      allArticleList: null,

      // dialogData:{
      //     visible: false,
      //     title: "dialog",
      //     width: '50%'
      // },

      //分页信息
      pagingInfo: {
        page_size: 1,
        current_page: 1
      },
      sgId: '',
      selectedList: []
    }
  },

  filters: {
    articleSimpleTitle: function (value) {
      if (!value) return ''
      return value.length > 16 ? (value.substring(0, 16) + '...') : value
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
    })
    window.onresize = this.onResize
  },

  methods: {
    onResize() {
      let clientHeight = window.innerHeight
      let formHeight = this.$refs.formRef ? this.$refs.formRef.$el.clientHeight : 0
      let bottomHeight = 60
      this.contentHeight = clientHeight - formHeight - bottomHeight - 49 - 100 - 48
      console.log(this.contentHeight)
    },
    setSgId(id) {
      this.sgId = id
      this.getArticleListPool();
    },

    //搜索
    onSearchClick() {
      this.$refs["apeTable"].resetCurPageSize()
      this.getArticleListPool(DEL, this.searchCondition);
    },

    //重置
    onResetSearchClick() {
      this.searchCondition = {}
      this.getArticleListPool()
    },

    //弹框取消
    dialogClose() {
      // this.dialogData.visible = false;
      this.$emit('dialog-close')
    },

    async getArticleListPool(type, params = {}) {
      if (!this.$refs['apeTable']) {
        return
      }

      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      inputData["sg_id"] = this.sgId
      let {list, pages} = await this.$api.poolStudyGuide(inputData);
      this.articleList = [];
      this.$nextTick(() => {
        this.articleList = list
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false

      console.log(this.selectArticleId, "aid")
      setTimeout(() => {
        for (let selected of this.selectArticleId) {
          this.$refs.apeTable.defaultCheckedRow(selected.id)
        }
      }, 200)

    },

    //弹框确定
    handleConfirm() {
      // if (this.selectArticleId.length <= 0) {
      //   this.$message("请选择要添加的文章");
      //   return;
      // }

      this.$emit('dialog-confirm', this.selectArticleId);

      // let info = await this.$api.selectSpecialTopicCategoryArticle(data);
      // if (info) {
      //     this.$message.success("添加成功");
      // } else {
      //     this.$message.error("添加失败");
      //     return;
      // }
      //
      // // this.iniQuestionTypeQuestionList();
      // this.selectArticleList = [];
      // this.selectArticleId = [];
      //
      // this.getSpecialTopicCategoryList(NO_DEL);
    },

    // resetAll() {
    //   this.searchCondition = {}
    //   this.selectArticleId = []
    //   this.articleList = []
    //   this.allArticleList = []
    //   this.$refs.apeTable.clearSelection()
    // },

    // 切换页码操作
    async switchPaging() {
      await this.getArticleListPool(NO_DEL)
      console.log(this.selectArticleId, "selected")
      // setTimeout(() => {
      //   for (let selected of this.selectArticleId) {
      //     this.$refs.apeTable.defaultCheckedRow(selected.id)
      //   }
      // }, 200)

    },
    // 全选
    async onHandleSelectionAllChange(ids) {
      // this.$refs.apeTable.clearSelection()
      // if (ids.length === 0) {
      //   this.selectArticleId = []
      //   this.$refs.apeTable.clearSelection()
      // } else {
      //   if (!this.allArticleList) {
      //     this.loadingStatus = true;
      //     let inputData = this.searchCondition
      //     inputData["current_page"] = 1
      //     inputData["page_size"] = 10000
      //     let {list} = await this.$api.poolStudyGuide(inputData);
      //     this.allArticleList = list
      //     this.loadingStatus = false
      //   }
      //
      //   this.selectArticleId = []
      //   for (let article of this.allArticleList) {
      //     this.selectArticleId.push({id: article.id, title: article.title})
      //   }
      // }

    },

    // 单选
    handleSelectionChange(id, selected) {
      this.$refs.apeTable.clearSelection()
      if (selected) {

        let found = this.articleList.find(item => item.id === id)
        if (found) {
          this.selectArticleId = [{id: id, title: found.title}]
        }

        this.$refs.apeTable.defaultCheckedRow(id)
      } else {
        let pos = this.selectArticleId.findIndex(item => item.id === id)
        if (pos > -1) {
          this.selectArticleId.splice(pos, 1)
        }
      }

    },

    onOpenSelectedArticleDialogClick() {

    },

    handleTagClose(tag) {
      this.$refs.apeTable.clearSelection()
      let id = tag.id
      let index = this.selectArticleId.findIndex(item => item.id === id)
      if (index === -1) {
        return
      }
      this.selectArticleId.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="stylus">
.el-tag {
  margin-left: 10px;
}

/deep/.el-table__header-wrapper  .el-checkbox{
display:none
}
</style>