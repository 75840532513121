<template>
  <div>
    <div class="main-page-content">
      <div style="display: flex;justify-content: space-between;padding-bottom: 12px">
        <div style="padding-left: 24px">
          <el-button type="primary" size="mini" @click="onSelectQuestion">选文章</el-button>
        </div>
        <div style="padding-right: 24px">
<!--          <el-switch-->
<!--              v-model="isSort"-->
<!--              inactive-text="列表排序">-->
<!--          </el-switch>-->
        </div>

      </div>

      <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-24" style="margin-top: 20px">
        <!--          <label class="color-primary">已选文章</label>-->
        <el-form :inline="true" :model="searchCondition" class="demo-form-inline">
          <el-form-item label="标题">
            <el-input placeholder="请输入文章标题" clearable v-model="searchCondition.title"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="onResetSearchClick">重置</el-button>
          </el-form-item>
        </el-form>

        <ape-table ref="existsApeTable"
                   :data="articleAddList"
                   :columns="articleColumns"
                   :loading="loadingStatus"
                   :pagingData="existsPagingData"
                   @selectList="handleSelectionChange"
                   @switchPaging="switchPaging"
                   highlight-current-row>

          <el-table-column
              v-if="buttonType== 'icon'"
              label="操作"
              width="200"
          >
            <template slot-scope="scope">
              <span v-if="!isSort">
                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                      <el-button type="danger"
                                 size="mini"
                                 v-if="userPermissions.indexOf('exam_guide_delete') != -1"
                                 icon="el-icon-delete"
                                 @click="deleteButton(scope.row.id)"
                      ></el-button>
                  </span>
<!--                  <span v-if="isSort">-->
<!--                <el-tooltip effect="dark" content="置顶" placement="top-start">-->
<!--                    <el-button size="mini" icon="el-icon-upload2" @click="onStickClick(scope.row.id)"></el-button>-->
<!--                </el-tooltip>-->
<!--                <el-tooltip effect="dark" content="置底" placement="top-start">-->
<!--                    <el-button size="mini" icon="el-icon-download" @click="onUndersideClick(scope.row.id)"></el-button>-->
<!--                </el-tooltip>-->
<!--                <el-tooltip effect="dark" content="向上排序" placement="top-start">-->
<!--                    <el-button size="mini" icon="el-icon-top" @click="onUpwardClick(scope.row.id)"></el-button>-->
<!--                </el-tooltip>-->
<!--                <el-tooltip effect="dark" content="向下排序" placement="top-start">-->
<!--                    <el-button size="mini" icon="el-icon-bottom" @click="onDownwardClick(scope.row.id)"></el-button>-->
<!--                </el-tooltip>-->
<!--              </span>-->
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              v-if="buttonType== 'text'"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>

                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                    <!--<el-popover-->
                    <!--v-if="userPermissions.indexOf('test_test_paper_delete') != -1"-->
                    <!--placement="top"-->
                    <!--width="150"-->
                    <!--v-model="scope.row.visible">-->
                    <!--<p>确定要删除记录吗？</p>-->
                    <!--<div style="text-align: right; margin: 0;">-->
                    <!--<el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
                    <!--<el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
                    <!--</div>-->
                    <!--<el-button slot="reference" type="danger" size="mini">删除</el-button>-->
                    <!--</el-popover>-->

                        <el-button type="danger"
                                   size="mini"
                                   v-if="userPermissions.indexOf('test_test_paper_delete') != -1"
                                   icon="el-icon-delete"
                                   @click="deleteButton(scope.row.id)"
                        ></el-button>
                  </span>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
        </ape-table>

      </el-col>

    </div>

    <!--    <ArticlePickDialog-->
    <!--        ref="articleDialog"-->
    <!--        :dialogData="dialogDate"-->
    <!--        :articleDialogData="articleDialogData"-->
    <!--        @dialog-close="handleArticleDialogClose"-->
    <!--        @dialog-confirm="handleArticleDialogConfirm"-->
    <!--    ></ArticlePickDialog>-->
    <el-dialog :visible.sync="isSelectExamGuideArticleDialog" :close-on-click-modal="false" width="80%" title="选择文章"
               :append-to-body="true" fullscreen style="margin: 50px" @opened="onOpenedSelectArticle"
               :destroy-on-close="true">
      <select-exam-guide-article ref="selectExamGuideArticleRef" @dialog-close="onSelectArticleClose" @dialog-confirm="handleArticleDialogConfirm"></select-exam-guide-article>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable';
import elTableInfiniteScroll from 'el-table-infinite-scroll';
import ChangeQuestionBank from '@/components/ChangeQuestionBank'
import {mapGetters} from 'vuex'
import ArticlePickDialog from '../ArticlePickDialog'
import SelectExamGuideArticle from "@/pages/exam_guide/component/SelectExamGuideArticle";

const NO_DEL = "no_del";
const DEL = "del";
const IS_SUCCESS = "ok";
export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ChangeQuestionBank,
    ArticlePickDialog,
    SelectExamGuideArticle
  },
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  data() {
    return {
      isSort: false,
      loadingStatus: true,
      questionBankQuestionList: [],
      summaryPagingData: {
        offset: undefined
      },

      existsPagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0, // 分页的offset,序号列使用
      },

      //题目数据
      articleColumns: [
        // {
        //   title: '标题',
        //   value: [
        //     {label: 'ID：', value: 'id'},
        //     {label: '标题：', value: 'title_alias', value_alias: 'title'},
        //     {label: '作者：', value: 'author_name'},
        //   ],
        //   width: '500'
        // },
        {
          title: '标题',
          value: 'title',
        },
        {
          title: '封面',
          type: 'image',
          value: 'cover_pic_url',
          width: '80'
        },
        {
          title: '缩略图',
          type: 'image',
          value: 'thumb_pic_url',
          width: '80'
        },
        {
          title: '发布时间',
          value: 'created_at',
          width: '180'
        }
      ],

      //分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0, // 分页的offset,序号列使用
      },

      questionList: [],
      modelVisible: false,
      isShowCard: false,
      tableSelectQuestionList: [],
      tableSelectList: [],
      tableData: [],
      totalPage: 1,
      cacheId: "",
      //修改分数和顺序
      modifyData: {
        score: "",
        order: ""
      },
      //过滤题目数组
      checkedQuestionList: [],
      totalCount: null,
      page: 1,
      //已经新增的表
      articleAddList: [],
      cId: "",
      // //分页信息
      pagingInfo: {
        page_size: 10,
        current_page: 1
      },

      //搜索条件
      searchCondition: {},
      searchParams: {},
      isSearch: false,
      qtqId: "",
      summaryQuestionList: [],
      qbId: "",

      //选择文章对话框配置
      dialogDate: {
        visible: false,
        title: "选择文章",
        width: '50%'
      },

      articleDialogData: {
        id: ""
      },

      stcId: "",
      //选中文章
      articleIds: [],

      sgId: "",
      isSelectExamGuideArticleDialog: false
    }
  },
  mounted() {
    // this.stcId = this.$route.params.c_id;
    // this.cacheId = new Date().getTime();
    // this.sgId = this.$route.params.sg_id;
    // this.resetArticleResourceList(DEL);
  },
  methods: {
    setExamGuideId(id) {
      this.sgId = id
      this.resetArticleResourceList(DEL)
    },
    //删除已选题目
    deleteButton(id) {

      this.$confirm('是否删除该已选文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = {
          sg_id: this.sgId,
          ids: [id],
        };

        let info = await this.$api.deleteStudyGuideResourceList(data);
        if (info) {
          this.$nextTick(() => {
            this.resetArticleResourceList(DEL);
            this.$message.success("删除成功");
          })
        } else {
          this.$message.error("删除失败")
        }
      })
    },

    //弹框
    onSelectQuestion() {
      // this.dialogDate.visible = true;
      // this.articleDialogData.id = this.sgId;
      // this.$nextTick(() => {
      //   this.$refs['articleDialog'].resetData();
      // })
      this.isSelectExamGuideArticleDialog = true
    },
    onOpenedSelectArticle() {
      this.$refs.selectExamGuideArticleRef.setSgId(this.sgId)
    },

    onSelectArticleClose() {
      this.isSelectExamGuideArticleDialog = false
    },

    //已选文章
    async resetArticleResourceList(type, params = {}) {
      this.loadingStatus = true;
      let pagingInfo = {}
      if (this.$refs['existsApeTable']) {
        pagingInfo = this.$refs['existsApeTable'].getPagingInfo(type);
      }
      if (!this.sgId) {
        this.$message("该条考试指南不存在");
        return;
      }

      if (type === DEL) {
        pagingInfo.current_page = 1
        this.$refs['existsApeTable'].resetCurPageSize();
      }

      let data = {
        page_size: pagingInfo.page_size,
        current_page: pagingInfo.current_page,
        sg_id: this.sgId
      };
      if (params && params.title) {
        data.title = params.title
      }

      let {list, pages} = await this.$api.selectStudyGuideResourceList(data);

      this.$nextTick(() => {

        this.articleAddList = list;

      });
      this.existsPagingData.total = pages.total;
      this.existsPagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    //切换页码
    async switchPaging() {
      this.resetArticleResourceList()
    },

    //------选择文章相关方法--------
    handleArticleDialogClose() {
      this.dialogDate.visible = false;
      this.resetArticleResourceList(DEL);
    },

    //文章弹框点击
    async handleArticleDialogConfirm(formData) {
      if (formData.length <= 0) {
        this.isSelectExamGuideArticleDialog = false
        return
      }

      // let formDataLen = formData.length;
      // let articleLen = this.articleAddList.length;

      // if (formDataLen + articleLen > 1) {
      //   this.$message.error("选择的文章最多不能超过1篇");
      //   return;
      // }

      if (!this.sgId) {
        this.$message("分类不能为空");
        return;
      }

      let params = {
        sg_id: this.sgId,
        articles: formData
      };


      this.$confirm('是否选择该条文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (this.articleAddList && this.articleAddList.length > 0) {
          let data = {
            sg_id: this.sgId,
            ids: [this.articleAddList[0].id],
          };
          await this.$api.deleteStudyGuideResourceList(data);
        }

        let info = await this.$api.selectStudyGuideArticle(params);
        if (info) {
          this.$nextTick(() => {
            this.$message.success("添加成功");
            this.isSelectExamGuideArticleDialog = false;
            this.resetArticleResourceList(DEL);
          })
        } else {
          this.$message.error("添加失败");
          return;
        }

      })




      //刷新列表
      // this.$refs['articleDialog'].resetData();

    },

    //获取所选择的文章列表
    handleSelectionChange(list) {
      this.articleIds = [];
      for (let article of list) {
        this.articleIds.push(article.id);
      }
    },

    onSearchClick() {
      this.resetArticleResourceList(DEL, this.searchCondition)
    },

    onResetSearchClick() {
      this.searchCondition = {}
      this.resetArticleResourceList(DEL)
    },

    //置顶
    async onStickClick(id) {
      let data = {
        id : id,
        type: 'top'
      }
      await this.$api.orderStudyGuideResourceList(data)
      await this.resetArticleResourceList(DEL)
    },
    //置底
    async onUndersideClick(id) {
      let data = {
        id : id,
        type: 'bottom'
      }
      await this.$api.orderStudyGuideResourceList(data)
      await this.resetArticleResourceList(NO_DEL)
    },
    //向上排序
    async onUpwardClick(id) {
      let data = {
        id : id,
        type: 'up'
      }
      await this.$api.orderStudyGuideResourceList(data)
      await this.resetArticleResourceList(NO_DEL)
    },
    //向下排序
    async onDownwardClick(id) {
      let data = {
        id : id,
        type: 'down'
      }
      await this.$api.orderStudyGuideResourceList(data)
      await this.resetArticleResourceList(NO_DEL)
    },

  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
  }
}
</script>

<style lang="stylus" scoped>
.color-primary
  color #1890ff

.text-over-hide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-layout {
  display flex
}

.margin-top-20 {
  margin-top 20px
}

.main-page-content {
  padding 0
}
</style>